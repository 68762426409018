import React from 'react';

const CallToActionContactHero = () => {
  return (
    <div className="sm:flex -mb-12 relative up-50">
      <p className="cta-left bg-blue-400 p-8 font-bold font-display leading-tight text-white text-xl md:text-2xl lg:text-3xl uppercase">
        <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
          Contact{' '}
        </span>
        <br />
        <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
          us today
        </span>
      </p>
    </div>
  );
};

export default CallToActionContactHero;
