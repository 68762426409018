import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ContactHero from '../components/ContactHero';
import CallToActionContactHero from '../components/CallToAction/ContactHero';
import Contact from '../components/Contact';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactHero />
    <CallToActionContactHero />
    <Contact primary="blue-400" secondary="blue-300" />
  </Layout>
);

export default ContactPage;
